import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEOHeader from "../components/seo-header"
import { kebabCase } from "lodash"
import Img from "gatsby-image"
import ReleatedBlog from "../components/blog/related-blog"
class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext
    const tags = post.frontmatter.tags
    const timeToRead = post.timeToRead
    const slug = post.fields.slug
    const filter = {
      category: post.frontmatter.category,
      slug: post.fields.slug,
    }
    return (
      <Layout location={this.props.location} title={siteTitle} type="blogPage">
        <SEOHeader
          title={post.frontmatter.title}
          description={post.frontmatter.description}
          socialLocalURL={post.frontmatter.featuredImage.publicURL}
          pinterest="true"
          socialURL=""
        ></SEOHeader>
        <div className="DesktopOnly">
          {post.frontmatter?.transparentHeader ? (
            <div
              className="specialBlogHeader"
              style={{
                backgroundImage: post.frontmatter.featuredImage?.publicURL
                  ? `url(${post.frontmatter.featuredImage.publicURL})`
                  : "", // Fallback image
              }}
            >
              <div className="container ">
                <div className="row">
                  <div className="col-md-12">
                    <h5 className="categoryLink">
                      <Link
                        to={`/blog/category/${kebabCase(
                          post.frontmatter.category
                        )}/`}
                      >
                        {post.frontmatter.category}
                      </Link>
                    </h5>
                    <h1>{post.frontmatter.title}</h1>
                    <h2>
                      {post.frontmatter.subTitle
                        ? post.frontmatter.subTitle
                        : ""}
                    </h2>
                    <p className="blogDate">
                      <a
                        className="authorlinks"
                        href={post.frontmatter.authorURL}
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        {post.frontmatter.author}
                      </a>
                      {" / "} Reading Time: {post.timeToRead} mins
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="MobileOnly">
          {post.frontmatter?.transparentHeader ? (
            <div className="specialHeaderBlog">
              <img
                src={post.frontmatter.featuredImage?.publicURL}
                className="img-responsive"
              ></img>
              <h5 className="categoryLink">
                <Link
                  to={`/blog/category/${kebabCase(post.frontmatter.category)}/`}
                >
                  {post.frontmatter.category}
                </Link>
              </h5>
            </div>
          ) : (
            ""
          )}

          <div className="container">
            <div className="mobile row">
              <div className="col-md-12">
                <br />
                <h1 style={{ fontWeight: "bold", fontSize: "1.7em" }}>
                  {post.frontmatter.title}
                </h1>
                <h2>
                  {post.frontmatter.subTitle ? post.frontmatter.subTitle : ""}
                </h2>
                <p className="blogDate">
                  By{" "}
                  <a
                    className="authorlinks"
                    href={post.frontmatter.authorURL}
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    {post.frontmatter.author}
                  </a>{" "}
                  &nbsp; &nbsp;<i class="fa fa-clock-o" aria-hidden="true"></i>
                  &nbsp;{post.timeToRead} Mins Read
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12 blog">
              {!post.frontmatter.transparentHeader ? (
                <div>
                  <h5 className="categoryLink">
                    <Link
                      to={`/blog/category/${kebabCase(
                        post.frontmatter.category
                      )}/`}
                    >
                      {post.frontmatter.category}
                    </Link>
                  </h5>
                  <h1>{post.frontmatter.title}</h1>
                  <h2>
                    {post.frontmatter.subTitle ? post.frontmatter.subTitle : ""}
                  </h2>
                  <p className="blogDate">
                    <a
                      className="authorlinks"
                      href={post.frontmatter.authorURL}
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                    >
                      {post.frontmatter.author}
                    </a>
                    {" / "} Reading Time : {post.timeToRead} mins
                  </p>
                  <Img
                    fluid={post.frontmatter.featuredImage.childImageSharp.fluid}
                    className="img-responsive"
                    style={{ borderRadius: "10px" }}
                    alt={post.frontmatter.title}
                  />
                </div>
              ) : (
                ""
              )}

              <div
                className="blogContent"
                dangerouslySetInnerHTML={{ __html: post.html }}
              />
              <hr />
              <ul className="socialShareButton">
                {" "}
                Share:
                <li>
                  <a
                    className="FacebookButton"
                    href={
                      "https://www.facebook.com/sharer.php?u=https://gofloaters.com/blog" +
                      slug
                    }
                    target="_blank"
                  >
                    <i className="fa fa-facebook"></i> Facebook
                  </a>
                </li>
                <li>
                  <a
                    className="TwitterButton"
                    href={
                      "https://twitter.com/intent/tweet?url=https://gofloaters.com/blog" +
                      slug +
                      "&text=" +
                      post.frontmatter.title +
                      "&hashtags=" +
                      post.frontmatter.category.toLowerCase().replace("-", "")
                    }
                    target="_blank"
                  >
                    <i className="fa fa-twitter"></i> Twitter
                  </a>
                </li>
                <li>
                  <a
                    className="linkedinButton"
                    href={
                      "https://www.linkedin.com/shareArticle?mini=true&url=https://gofloaters.com/blog" +
                      slug +
                      "&title=" +
                      post.frontmatter.title +
                      "&summary=" +
                      post.frontmatter.description +
                      "&source=GoFloaters"
                    }
                    target="_blank"
                  >
                    <i className="fa fa-linkedin"></i> LinkedIn
                  </a>
                </li>
              </ul>
              <br></br>
              <h5>
                Category:{" "}
                <b>
                  {" "}
                  <Link
                    to={`/blog/category/${kebabCase(
                      post.frontmatter.category
                    )}/`}
                  >
                    {post.frontmatter.category}
                  </Link>
                </b>
              </h5>
              {tags && tags.length ? (
                <div style={{ marginTop: `1rem` }}>
                  <h5>tags: </h5>
                  <ul className="taglist">
                    {tags.map(tag => (
                      <li key={tag + `tag`}>
                        <Link to={`/blog/tags/${kebabCase(tag)}/`}>{tag}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
              <div className="row">
                <ReleatedBlog filter={filter} />
              </div>
              <br></br>
              {/*<ul
                style={{
                  display: `flex`,
                  flexWrap: `wrap`,
                  justifyContent: `space-between`,
                  listStyle: `none`,
                  padding: 0,
                }}
              >
                <li>
                  {next && (
                    <Link to={"/blog" + next.fields.slug} rel="next">
                      ← {next.frontmatter.title}
                    </Link>
                  )}
                </li>
                <li>
                  {previous && (
                    <Link to={"/blog" + previous.fields.slug} rel="prev">
                      {previous.frontmatter.title} →
                    </Link>
                  )}
                </li>
                  </ul> */}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      tableOfContents
      timeToRead
      fields {
        slug
      }
      frontmatter {
        title
        subTitle
        date
        author
        tags
        category
        description
        authorURL
        transparentHeader
        featuredImage {
          relativeDirectory
          publicURL
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      timeToRead
    }
  }
`
